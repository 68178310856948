<template>
  <div>
    <validation-observer
      ref="domainEditForm"
      #default="{invalid}"
    >
      <b-modal
        id="edit-modal"
        ref="edit-modal"
        no-close-on-backdrop
        ok-title="確認"
        centered
        header-bg-variant="primary"
        ok-only
        size="lg"
        @hidden="handleHide"
        @close="handleHide"
        @ok="handleOk"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            編輯經銷商
          </h4>
        </template>

        <div v-if="!isLoadingBusy">
          <b-form
            @reset.prevent="resetForm"
            @submit.prevent="handleOk"
          >
            <b-row>
              <!-- 網域商 -->
              <b-col
                cols="12"
                lg="12"
              >
                <b-form-group
                  label="網域商"
                  label-for="domain_agent"
                >
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        網域商
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <div class="d-flex">
                    <v-select
                      v-model="domainData.domain_name_agent_id"
                      :options="adminDomainState.domainAgentOptions"
                      :clearable="false"
                      input-id="domain_agent"
                      placeholder="請選擇要使用的金流商"
                      :reduce="option => option.id"
                      class="select-type w-100"
                      label="agent"
                      @input="onSelectDealerAgent(domainData.domain_name_agent_id ,() => {})"
                    >
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          沒有 <em>{{ search }}</em> 的相關結果
                        </template>
                      </template>

                      <template v-slot:option="option">
                        <div>
                          <div
                            class="font-weight-bold d-block text-nowrap mb-0"
                          >
                            {{ option.agent }}
                          </div>
                        </div>
                      </template>

                      <template #selected-option="option">
                        <div>
                          <div
                            class="font-weight-bold d-block text-nowrap mb-0"
                          >
                            {{ option.agent }}
                          </div>
                        </div>
                      </template>
                    </v-select>

                    <toggle-button
                      v-model="copyState"
                      :color="{checked: '#20c997', unchecked: '#8f8f8f'}"
                      :switch-color="{checked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)', unchecked: 'linear-gradient(40deg,#9f9f9f,#ffffff 70%)'}"
                      :width="60"
                      :height="24"
                      class="mt-50 ml-1"
                      :labels="{checked: '自動', unchecked: '預設'}"
                      :sync="true"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <!-- 名稱 -->
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group label-for="domainDealer">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        代號
                        <span class="text-danger">*</span>
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="代號"
                    rules="required"
                  >
                    <b-form-input
                      id="domainDealer"
                      v-model="domainData.name"
                      trim
                      placeholder="請輸入經銷商代號"
                    />
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- 密碼規則 -->
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group label-for="token">
                  <template #label>
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="mb-0">
                        密碼規則
                      </label>
                    </div>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="密碼規則"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="domainData.token"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        placeholder="**********"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'"
                          class="cursor-pointer"
                          @click="togglePassword"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <div
              v-if="domainData.domain_name_agent_id"
              class="animate__animated animate__fadeIn"
            >

              <div class="d-flex align-items-center justify-content-between font-weight-bold">
                <div>
                  <feather-icon
                    icon="BoxIcon"
                    class="mr-50"
                    style="margin-top: -3px"
                  />

                  <span style="font-size: '16px'">參數設定</span>
                </div>

                <div class="text-nowrap d-flex justify-content-end align-items-center mt-50">
                  <div
                    class="actions-link-btn mr-25"
                    @click="onSelectDealerAgent(domainData.domain_name_agent_id ,() => {})"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="重新整理"
                      src="/dashboard/admin/images/table/refresh.svg"
                      class="actions-link-btn-image"
                      width="14"
                      rounded
                    />
                  </div>

                  <div
                    class="actions-link-btn mr-25"
                    @click="onClearDealerAgent"
                  >
                    <b-img
                      v-b-tooltip.hover.focus.v-secondary
                      title="清空"
                      :src="require('@/assets/images/pages/icons/trash-bin-1.svg')"
                      class="actions-link-btn-image"
                      width="14"
                      rounded
                    />
                  </div>
                </div>
              </div>

              <hr class="mt-50">

              <div v-if="!initBusy">
                <b-form-group
                  v-for="(key, index) in domainAgent"
                  :key="`config_key-${index}`"
                  :label-cols="$store.getters['app/currentBreakPoint'] === 'xs' ? 12 : 4"
                  class="m-0 mb-1"
                >
                  <template #label>
                    <label
                      :for="key.key"
                      class="mb-0"
                    >
                      {{ key.name }}
                      <!-- / {{ key.type }} -->
                      <span
                        v-if="key.required"
                        class="text-danger"
                      >*</span>
                    </label>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    :name="key.name"
                    :rules="generateRules(key.rules)"
                  >
                    <div v-if="key.type === 'TextType'">
                      <b-form-input
                        :id="key.key"
                        v-model="key.value"
                        :name="key.key"
                        trim
                        :placeholder="`請輸入${key.name}`"
                      />
                    </div>

                    <div v-if="key.type === 'NumericType'">
                      <b-form-input
                        :id="key.key"
                        v-model="key.value"
                        :name="key.key"
                        trim
                        type="number"
                        :placeholder="`請輸入${key.name}`"
                      />
                    </div>

                    <div v-if="key.type === 'BooleanType'">
                      <v-select
                        v-model="key.value"
                        :options="[{
                          label: 'True', value: true,
                        }, {
                          label: 'False', value: false,
                        }]"
                        class="b-form-select"
                        :clearable="false"
                        :reduce="option => option.value"
                        :placeholder="`請選擇${key.name}`"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <div v-if="key.type === 'DateType'">
                      <flat-pickr
                        :id="key.key"
                        v-model="key.value"
                        class="form-control card-input"
                        :config="dateDefaluteConfig"
                        :placeholder="`${key.options.format !== '自訂時間' ? key.options.format : key.options.input}`"
                      />
                    </div>

                    <div v-if="key.type === 'DropdownType'">
                      <v-select
                        v-model="key.value"
                        :options="key.options.items"
                        class="b-form-select"
                        :reduce="option => option.value"
                        :placeholder="`請選擇${key.name}`"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <div v-if="key.type === 'DropdownMutiType'">
                      <v-select
                        v-model="key.value"
                        :options="key.options.items"
                        class="b-form-select"
                        multiple
                        :reduce="option => option.value"
                        :placeholder="`請選擇${key.name}`"
                      >
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            沒有<em>{{ search }}</em> 的相關結果
                          </template>

                          <div v-else>
                            <small>暫無資料</small>
                          </div>
                        </template>
                      </v-select>
                    </div>

                    <div v-if="key.type === 'CountryType'">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <CountrySelect
                            :default-country="key.options.iso2"
                            @select-country="(item) => {
                              key.options.iso2 = item.iso2
                              key.options.default = item
                              key.value = item.name
                            }"
                          />
                        </b-input-group-prepend>

                        <b-form-input
                          id="config-country"
                          v-model="key.value"
                          :placeholder="`請填入${key.name}`"
                        />
                      </b-input-group>
                    </div>

                    <div v-if="key.type === 'PhoneType'">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <CountrySelect
                            :default-country="key.options.iso2"
                            @select-country="(item) => {
                              key.options.dialCode = item.dialCode
                              key.options.iso2 = item.iso2
                            }"
                          />
                          <span
                            v-if="key.options.dialCode"
                            class="ml-1"
                          > +{{ key.options.dialCode }} </span>
                        </b-input-group-prepend>

                        <b-form-input
                          id="config-phone"
                          v-model="key.value"
                          :placeholder="`請填入${key.name}`"
                        />
                      </b-input-group>
                    </div>

                    <small
                      v-if="errors[0]"
                      class="ml-25 text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </div>

              <div
                v-else
                class="loading-area"
              >
                <b-img
                  :src="$store.state.app.themeImages.loadingImg"
                  rounded
                  height="60"
                  width="60"
                />
              </div>

              <!-- {{ domainAgent }} -->
            </div>
          </b-form>
        </div>

        <div
          v-else
          class="loading-area"
        >
          <b-img
            :src="$store.state.app.themeImages.loadingImg"
            rounded
            height="60"
            width="60"
          />
        </div>

        <template #modal-footer>
          <div class="w-100 text-right">
            <b-button
              variant="primary"
              :disabled="invalid || isBusy"
              @click="handleOk"
            >
              <span v-if="!isBusy">確認</span>

              <b-spinner
                v-else
                small
              />
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormGroup, BImg, BFormInput, BRow, BCol, VBTooltip, BButton, BSpinner, BInputGroup,
  BInputGroupAppend, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ToggleButton } from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import { min, max, regex } from 'vee-validate/dist/rules'
import {
  ValidationObserver, ValidationProvider, extend,
} from 'vee-validate'
import moment from 'moment'
import language from 'flatpickr/dist/l10n/zh-tw'
import CountrySelect from '@/layouts/components/CountrySelect.vue'
import { useDomainDealer, useDomainAgent, useDomainSetting } from '../useDomain'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BForm,
    BModal,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
    CountrySelect,
    ToggleButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    domainItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      missingFields: [],
      required,
      min,
      max,
      regex,
      passwordFieldType: 'password',
      copyState: true,
    }
  },
  computed: {
    adminDomainState() {
      return this.$store.state['admin-domain']
    },
  },
  mounted() {
    extend('min', min)
    extend('max', max)
    extend('regex', regex)
  },
  methods: {
    moment,
    // 顯示密碼/隱藏密碼
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDomainDealerData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.isBusy) return

      const requiredFields = {
        name: '網域商代號',
        domain_name_agent_id: '網域商',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.domainData[Object.keys(requiredFields)[i]] === null || this.domainData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }

      const rulesValue = {}

      for (let i = 0; i < this.domainAgent.length; i += 1) {
        const item = this.domainAgent[i]
        let { value } = item
        if (item.required && (value === null || value === '')) {
          this.useAlertToast(false, `${item.name}不得為空`)
          return
        }
        if (item.type === 'NumericType') {
          value = Number(value)
          if (item.options.min) {
            if (value < item.options.min) {
              this.useAlertToast(false, `${item.name}不得小於 ${item.options.min}`)
              return
            }
          }
          if (item.options.max) {
            if (value > item.options.max) {
              this.useAlertToast(false, `${item.name}不得大於 ${item.options.max}`)
              return
            }
          }
        }
        if (item.type === 'DropdownMutiType') {
          if (Array.isArray(value)) {
            value = value.join(item.options.delimiter)
          }
        }
        if (item.type === 'DateType') {
          switch (item.options.format) {
            case 'Y-m-d H:i:s':
              value = moment(value).format('YYYY-MM-DD HH:mm:ss')
              break
            case 'Y/m/d H:i:s':
              value = moment(value).format('YYYY/MM/DD HH:mm:ss')
              break
            case 'Ymd':
              value = moment(value).format('YYYYMMDD')
              break
            case 'Y-m-d H:i:s:u':
              value = moment(value).format('YYYY-MM-DD HH:mm:ss:SSS')
              break
            case 'Y/m/d':
              value = moment(value).format('YYYY/MM/DD')
              break
            case 'm/d':
              value = moment(value).format('MM/DD')
              break
            case 'unix(s)':
              value = moment(value).unix()
              break

            case 'unix(ms)':
              value = moment(value).valueOf()
              break
            default:
              if (item.options.format === '自訂時間') value = moment(value).format(item.options.input)
              else value = moment().format('YYYY-MM-DD HH:mm:ss')
          }
        }
        if (item.type === 'CountryType') {
          value = item.options.iso2
        }

        if (item.type === 'PhoneType' && item.value) {
          value = `+${item.options.dialCode}.${item.value}`
        }
        rulesValue[item.key] = value
      }

      const resolveData = {
        ...this.domainData,
        rules: rulesValue,
      }

      if (!resolveData.token) {
        delete resolveData.token
      }

      this.isBusy = true
      this.$refs.domainEditForm.validate()
        .then(success => {
          if (success) {
            this.setDomainDealerUpdate({
              id: this.domainItem.id,
              data: {
                ...resolveData,
              },
            })
              .then(response => {
                this.useAlertToast(response.data.success, response.data.message)
                this.isBusy = false
                this.$emit('refetch-data')
                this.resetDomainDealerData()
                this.$nextTick(() => {
                  this.$bvModal.hide('edit-modal')
                })
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          } else {
            this.useAlertToast(false, '資料填寫不完全')
            this.isBusy = false
          }
        })
    },

    // (正規)驗證
    generateRules(rules) {
      const generatedRules = {}
      Object.entries(rules).forEach(([rule, value]) => {
        if (value) {
          generatedRules[rule] = value
        }
      })
      return generatedRules
    },

    // (清空)網域設定
    onClearDealerAgent() {
      this.domainAgent = this.domainAgent.map(agent => {
        const resolve = {
          ...agent,
          value: null,
        }
        return resolve
      })
    },

    // (選擇)網域商
    onSelectDealerAgent(selectedId, callback) {
      if (!selectedId) return
      this.initBusy = true
      this.domainAgent = []
      this.getDomainAgent({
        id: selectedId,
      })
        .then(response => {
          const { data } = response.data
          let resolveConfigKey = []
          if (data.rules && data.rules.length > 0) {
            resolveConfigKey = data.rules.map(item => {
              const resolveItem = this.syncObject(this.blankSetting, item)
              return resolveItem
            })
          }
          this.domainAgent = resolveConfigKey.map(setting => {
            const resolveData = {
              key: setting.key,
              name: setting.name,
              required: setting.required,
              type: setting.type,
              value: setting.options.default ? setting.options.default : null,
              rules: {
                ...(setting.required ? { required: true } : {}),
                ...(setting.rule ? { regex: new RegExp(setting.rule) } : {}),
              },
              options: setting.options,
            }

            if (resolveData.type === 'TextType') {
              resolveData.rules = {
                ...resolveData.rules,
                ...(setting.options.min ? { min: setting.options.min } : {}),
                ...(setting.options.max ? { max: setting.options.max } : {}),
              }
            }
            if (this.copyState) {
              if (this.domainItem.rules && this.domainItem.rules[resolveData.key]) {
                resolveData.value = this.domainItem.rules[resolveData.key]
                if (resolveData.type === 'PhoneType' && resolveData.value) {
                  if (resolveData.value) {
                    const dialCode = resolveData.value.match(/\+(\d+)\./)
                    const code = dialCode ? dialCode[1] : null

                    if (code) {
                      resolveData.options.dialCode = dialCode
                      const iso2 = this.resolveCountriesCode(code)
                      resolveData.options.iso2 = iso2
                    }
                  }
                  resolveData.value = resolveData.value.substring(resolveData.value.indexOf('.') + 1)
                }

                if (resolveData.type === 'CountryType' && resolveData.value) {
                  if (resolveData.value) resolveData.options.iso2 = resolveData.value
                }
              }
            }
            return resolveData
          })
          this.initBusy = false
          callback()
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
        })
    },

    // (開啟)新增金流商
    getData() {
      this.isLoadingBusy = true
      this.$bvModal.show('edit-modal')
      this.getDomainDealer({
        id: this.domainItem.id,
      })
        .then(response => {
          const { data } = response.data
          const resolveData = this.syncObject(this.blankDomainDealerData, data)
          this.onSelectDealerAgent(resolveData.domain_name_agent_id, () => {
            if (resolveData.rules && Object.keys(resolveData.rules).length > 0) {
              Object.keys(resolveData.rules).forEach(key => {
                const findIndex = this.domainAgent.findIndex(item => item.key === key)
                if (findIndex > -1) {
                  const item = this.domainAgent[findIndex]
                  let value = resolveData.rules[key]

                  if (item.type === 'DropdownMutiType') {
                    value = value.split(item.options.delimiter)
                  }
                  if (item.type === 'PhoneType' && value) {
                    if (value) {
                      const match = value.match(/\+(\d+)\./)
                      const dialCode = match ? match[1] : null

                      if (dialCode) {
                        item.options.dialCode = dialCode
                        const iso2 = this.resolveCountriesCode(dialCode)
                        item.options.iso2 = iso2
                      }
                    }
                    value = value.substring(value.indexOf('.') + 1)
                  }

                  if (item.type === 'CountryType' && value) {
                    if (value) item.options.iso2 = value
                  }
                  item.value = value
                }
              })
            }
          })
          this.domainData = resolveData
          this.isLoadingBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
        })
    },
  },
  setup() {
    const {
      isBusy,
      isLoadingBusy,
      domainData,
      resolveCountriesCode,

      getDomainDealer,
      setDomainDealerUpdate,
      getDomainAgentData,
      useAlertToast,
      useHttpCodeAlert,
    } = useDomainDealer()

    const {
      syncObject,
      resolveRuleMode,
    } = useDomainSetting()

    const {
      initBusy,
      domainAgent,
      blankSetting,
      getDomainAgent,
    } = useDomainAgent()

    const blankDomainDealerData = {
      id: null,
      name: null,
      token: null,
      domain_name_agent_id: null,
      rules: [],
    }

    const dateDefaluteConfig = {
      locale: language.zh_tw,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
    }

    domainData.value = JSON.parse(JSON.stringify(blankDomainDealerData))

    const resetDomainDealerData = () => {
      domainData.value = JSON.parse(JSON.stringify(blankDomainDealerData))
      domainAgent.value = []
    }

    return {
      isBusy,
      isLoadingBusy,
      blankDomainDealerData,
      domainData,
      resolveCountriesCode,
      getDomainDealer,
      setDomainDealerUpdate,
      getDomainAgentData,
      useAlertToast,
      useHttpCodeAlert,
      resetDomainDealerData,

      initBusy,
      domainAgent,
      blankSetting,
      getDomainAgent,

      syncObject,
      resolveRuleMode,
      dateDefaluteConfig,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
</style>
